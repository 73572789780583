<template>
  <div class="planning"  v-touch:swipe.left="onSwipeLeft"  v-touch:swipe.right="onSwipeRight">
      <div class="title">
          <div class="t1">{{user.next_level_name}}全年规划图</div>
          <div class="t2">{{user.next_level_name}} ANNUAL PLAN</div>
        </div>
      <img src="https://alicdn.joyballet.cn/studentStudy/all_year_plan.png"  ondragstart="return false;" alt="">
      <div class="to" @click="onSwipeLeft">
          <img src="../assets/img/home/right.png" alt="">
      </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
export default {
  data() {
    return {
      title: '',
      user: '',
      level: '',
      img: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('student'));
  },
  mounted() {
    // js
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    onSwipeLeft() {
      this.$router.push({
        name: 'End',
      });
    },
  },
};
</script>
<style lang="less">
.planning{
    width: 100vw;
    // height: 100vh;
    position: relative;
    background: #fffdfa;
    .title{
        position: absolute;
        top: 6.4vw;
        width: 100vw;
        text-align: center;
        font-size: 0.46rem;
        line-height: 0.63rem;
        color:#333029 ;
        z-index: 100;
        .t1{
            font-weight: bold;
        }
        .t2{
            line-height: 0.2rem;
            font-size: 0.14rem;
            margin-top: 0.04rem;
        }
    }
    img{
        width: 100%;
    }
}
</style>
